@use 'sass:math';
@use '../_mixin' as m;
/*----------
	components default margin
	コンポーネント間デフォルトマージン設定
----------*/
.l-default-margin {
	// default
	> [class^="c-"],
	> [class^="g-"] {
		margin-top: 40px;
		@include m.mq-pc {
			margin-top: 50px;
		}
	}

	// text
	> .c-heading-secondary {
		margin-top: 80px;
		margin-bottom: 40px;
		@include m.mq-pc {
			margin-top: 120px;
			margin-bottom: 50px;
		}
	}
	> .c-heading-tertiary {
		margin-top: 60px;
		margin-bottom: 40px;
		@include m.mq-pc {
			margin-top: 80px;
			margin-bottom: 50px;
		}
	}
	> .c-heading-quaternary {
		margin-top: 40px;
		margin-bottom: 20px;
		@include m.mq-pc {
			margin-top: 50px;
			margin-bottom: 30px;
		}
	}

	> .g-button-container {
		margin-top: 60px;
		@include m.mq-pc {
			margin-top: 80px;
		}
	}

	> .c-caption {
		margin-top: 30px;
	}
}

// テンプレート固定要素用
.l-main {
	> .c-billboard-catetop {
		margin-bottom: 80px;
		@include m.mq-pc {
			margin-bottom: 100px;
		}
	}

	> .c-billboard {
		margin-bottom: 80px;
		@include m.mq-pc {
			margin-bottom: 120px;
		}
	}
}

// コンテンツボックス内部デフォルト
// .c-box__body {
// 	// default
// 	> [class^="c-"]:not(:first-child),
// 	> [class^="g-"]:not(:first-child) {
// 		margin-bottom: 30px;
// 		@include m.mq-pc {
// 			margin-bottom: 40px;
// 		}
// 	}
// }


