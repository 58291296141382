@use 'sass:math';
@use '../_mixin' as m;
/*----------
	main footer
----------*/
$_root: '.l-mainfooter';

.l-mainfooter {
	position: relative;
	border-top: 1px solid var(--color-border);

	&__inner {
		background: url(/assets/images/bg-line-bottom.png) no-repeat left bottom / 180px auto;
		@include m.inner-content;
		padding-top: 182px;
		padding-bottom: 100px;
		position: relative;
	}

	&__logo {
		width: 110px;
		position: absolute;
		top: 40px;
		left: 5.3%;
	}

	&__productlink {
		position: absolute;
		top: 60px;
		right: 5.3%;

		> a {
			display: block;
			width: 120px;
			height: 42px;
			border-radius: 41px;
			background-color: #222;
			text-align: center;
			text-decoration: none;
			font-weight: 500;
			font-size: 1.3rem;
			line-height: 42px;
			color: #fff;

			&::after {
				@include m.iconfont-default;
				content: map-get(m.$icons, 'blank');
				margin-left: 8px;
			}
		}
	}

	&__nav {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 48px;

		> ul {
			> li {
				> a {
					display: block;
					text-decoration: none;
					font-size: 1.2rem;
					line-height: var(--line-height-m);
					color: var(--color-text-caption);

					> i {
						margin-left: 6px;
					}
				}

				+ li {
					margin-top: 16px;
				}

				&.cattop {
					> a {
						font-weight: bold;
						font-size: 1.4rem;
						color: var(--color-text);
					}

					+ li {
						margin-top: 24px;
					}
				}
			}
		}
	}

	&__util {
		background-color: #fff;

		&__inner {
			@include m.inner-content;
			padding-top: 24px;
			padding-bottom: 24px;
		}
	}

	&__utilnav {
		> li {
			display: inline-block;
			margin-right: 15px;
			padding-right: 15px;
			border-right: 1px solid var(--color-border);
			line-height: 1;

			> a {
				display: block;
				text-decoration: none;
				font-size: 1.2rem;
				color: var(--color-text-caption);
			}
		}
	}

	&__copyright {
		margin-top: 48px;
		text-align: left;
		font-size: 1.2rem;
		color: var(--color-text-caption);
	}


	@include m.mq-pc {
		background: url(/assets/images/bg-line-bottom.png) no-repeat left bottom 100px / 250px auto;

		&__inner {
			padding-top: 80px;
			padding-left: 340px;
			background: none;
		}

		&__logo {
			width: 150px;
			top: 80px;
			left: 40px;
		}

		&__productlink {
			top: 264px;
			left: 40px;
			right: auto;
		}

		&__nav {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			gap: 60px;

			> ul {
				> li {
					> a {
						font-size: 1.4rem;
					}
					&.cattop {
						> a {
							font-size: 1.6rem;
						}
					}
				}
			}
		}

		&__util {
			&__inner {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}

		&__utilnav {
			> li {
				> a {
					font-size: 1.4rem;
				}
			}
		}

		&__copyright {
			margin-top: 0;
			text-align: right;
			font-size: 1.4rem;
		}
	}

	@media print {
		display: none;
	}
}

.l-backhead {
	display: block;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 100;
	background-color: #fff;
	box-shadow: 0 3px 6px rgba(0,0,0,0.2);
	cursor: pointer;

	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: opacity 0.2s linear, visibility 0.2s linear;

	&::before {
		@include m.iconfont-default;
		content: map-get(m.$icons, 'chev-up');
		display: block;
		width: 12px;
		height: 12px;
		position: absolute;
		top: 15px;
		left: 50%;
		font-size: 12px;
		color: var(--color-text);
		transform: translateX(-50%);
	}

	&::after {
		content: "";
		display: block;
		width: 10px;
		height: 2px;
		position: absolute;
		bottom: 17px;
		left: 50%;
		background-color: var(--color-text);
		transform: translateX(-50%);
	}

	&.active {
		opacity: 1;
		visibility: visible;
		pointer-events: all;
	}

	@include m.mq-pc {
		width: 70px;
		height: 70px;

		&::before {
			width: 18px;
			height: 18px;
			top: 21px;
			font-size: 18px;
		}

		&::after {
			width: 14px;
			height: 2px;
			bottom: 24px;
		}
	}

	@include m.mq-hover {
		&:hover {
			opacity: 0.7;
		}
	}
}
