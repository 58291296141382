@use 'sass:math';
@use '../_mixin' as m;
/*----------
	main header
----------*/
$_root: '.l-mainheader';

.l-mainheader {
	position: relative;
	z-index: 120;

	&__logo {
		width: 90px;
		position: absolute;
		top: 15px;
		left: 5.3%;
		z-index: 100;

		> a {
			display: block;
		}
	}

	&__inner {
		width: 220px;
		height: 70px;
		border-bottom-left-radius: 10px;
		position: fixed;
		top: 0;
		right: 0;
		background-color: #fff;
		box-shadow: 0px 2px 4px rgba(0,0,0,0.05);
	}

	&__navtoggle {
		display: block;
		width: 50px;
		height: 50px;
		padding: 0 5px;
		position: absolute;
		top: calc(50% - 25px);
		right: 15px;
		z-index: 105;
		cursor: pointer;

		> div {
			width: 40px;
			height: 30px;
			position: absolute;
			top: 0;
			left: 5px;

			> span,
			&::before,
			&::after {
				content: "";
				display: block;
				width: 40px;
				height: 1px;
				position: absolute;
				top: 50%;
				left: 0;
				background-color: #000;
				transform-origin: center;
			}
			> span {
				transform: opacity 0.2s linear;
			}
			&::before {
				transform: translateY(-10px) rotate(0);
				transition: transform 0.2s ease-out,
			}
			&::after {
				transform: translateY(10px) rotate(0);
				transition: transform 0.2s ease-out,
			}
		}

		> span {
			display: block;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			font-family: var(--font-raj);
			font-weight: 500;
			font-size: 1.4rem;
			line-height: 1;
			color: var(--color-text);
		}
	}

	&__productlink {
		position: absolute;
		top: calc(50% - 21px);
		right: 80px;
		z-index: 102;

		> a {
			display: block;
			width: 120px;
			height: 42px;
			border-radius: 41px;
			background-color: #222;
			text-align: center;
			text-decoration: none;
			font-weight: 500;
			font-size: 1.3rem;
			line-height: 42px;
			color: #fff;

			&::after {
				@include m.iconfont-default;
				content: map-get(m.$icons, 'blank');
				margin-left: 8px;
			}
		}
	}

	&__nav {
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		transition: opacity 0.2s linear, visibility 0.2s linear;
	}

	@media (max-width: 1057px) {
		&__rayer {
			content: "";
			display: block;
			width: 100vw;
			height: 100vh;
			position: fixed;
			z-index: -1;
			top: 0;
			left: 0;
			background: #fff no-repeat center / cover;
			background-image: radial-gradient(#ffffff, #e4eef2);

			opacity: 0;
			visibility: hidden;
			pointer-events: none;
			transition: opacity 0.2s linear, visibility 0.2s linear;
		}

		&.sp-nav-active {
			#{$_root}__rayer {
				opacity: 1;
				visibility: visible;
				pointer-events: all;
			}

			#{$_root}__logo {
				position: fixed;
			}

			#{$_root}__navtoggle {
				> div {
					> span {
						opacity: 0;
					}
					&::before {
						transform: translateY(0) rotate(30deg);
					}
					&::after {
						transform: translateY(0) rotate(-30deg);
					}
				}
			}

			#{$_root}__nav {
				opacity: 1;
				visibility: visible;
				pointer-events: all;
			}
		}
	}

	@media (min-width: 1058px) {
		min-width: 1280px;

		&__rayer {
			display: block;
			width: 100vw;
			height: 100vh;
			position: fixed;
			z-index: -1;
			top: 0;
			left: 0;
			background-color: rgba(#000, 0.5);

			opacity: 0;
			visibility: hidden;
			pointer-events: none;
			transition: opacity 0.2s linear, visibility 0.2s linear;
		}

		&:has(.pc-subnav-active) {
			#{$_root}__rayer {
				opacity: 1;
				visibility: visible;
				pointer-events: all;
			}
		}

		&__logo {
			width: 135px;
			left: 40px;
		}

		&__inner {
			width: auto;
			height: 86px;
		}

		&__navtoggle {
			display: none;
		}

		&__productlink {
			right: 134px;
		}

		&__nav {
			opacity: 1;
			visibility: visible;
			pointer-events: all;
		}
	}

	@include m.mq-hover {
		&:not(.is-nav-active) {
			#{$_root}__navtoggle {
				&:hover {
					background-color: #fff;

					> span {
						color: var(--color-prim);
					}

					> i {
						&,
						&::before,
						&::after {
							background-color: var(--color-prim);
						}
					}
				}
			}
		}
	}

	@media print {
		display: none;
	}
}
