@use 'sass:math';
@use '../_mixin' as m;
/*----------
	breadcrumb
	パンくずリスト
----------*/

.l-breadcrumb {
	padding: 17px 0;
	overflow-x: auto;

	&__list {
		display: flex;
		flex-wrap: nowrap;
		max-width: 1280px;
		margin: 0 auto;
		padding: 0 5.3%;
		white-space: nowrap;
		word-break: keep-all;
		font-size: 1.2rem;
		line-height: 1.2;

		> li {
			> a {
				text-decoration: none;
				color: var(--color-text-caption);
			}

			+ li {
				&::before {
					content: '>';
					display: inline-block;
					margin: 0 8px;
					color: var(--color-text-caption);
				}
			}

			&:last-child {
				> a {
					pointer-events: none;
					font-weight: 500;
					color: var(--color-text);
				}
			}
		}
	}

	@include m.mq-pc {
		padding: 24px 0;
		overflow-x: hidden;

		&__list {
			padding: 0 40px;
		}
	}

	@include m.mq-hover {
		&__list {
			> li {
				> a {
					transition-property: opacity;
					transition-duration: 0.2s;
					transition-timing-function: linear;

					&:hover {
						opacity: 0.7;
					}
				}
			}
		}
	}

	@media print {
		display: none;
	}
}

// カテゴリートップレイアウト
.l-content--cattop {
	.l-breadcrumb {
		width: 100%;
		position: absolute;
		top: 90px;
		left: 0;
		z-index: 10;

		&__list {
			> li {
				> a {
					color: #fff;
				}
			}
		}

		@include m.mq-pc {
			top: 145px;
		}
	}
}
