@use 'sass:math';
@use '../_mixin' as m;
/*----------
	main content
----------*/

// コンテンツ全体枠
.l-content {
	width: 100%;
	padding-bottom: 124px;
	position: relative;
	z-index: 2;
	background: url(/assets/images/bg-line-top.png) no-repeat right top / 240px auto;

	&:has(#localNav) {
		padding-bottom: 100px;
	}

	@include m.mq-pc {
		background-size: 450px auto;
		min-width: 1280px;
		padding-bottom: 184px;

		&:has(#localNav) {
			padding-bottom: 110px;
		}
	}
}

.l-main {
	width: 100%;
	position: relative;
	overflow: hidden;
}

// セクション枠 最大幅設定・中央配置
.l-section {
	@include m.inner-content;
}
