@use 'sass:math';
@use '../_mixin' as m;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Rajdhani:wght@400;500&display=swap');

/*----------
	base
----------*/

html {
	width: 100%;
	font-size: 62.5%;

	&.locked {
		overflow: hidden;
	}
}

body {
	position: relative;
	font-size: 1.6rem;
	line-height: var(--line-height-l);
	font-family: var(--font-sans);
	letter-spacing: 0;
	overflow-wrap: anywhere;
	word-break: normal;
	line-break: strict;
	color: var(--color-text);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;

	&::before {
		content: "";
		display: block;
		width: 100vw;
		height: 100vh;
		position: fixed;
		z-index: -1;
		top: 0;
		left: 0;
		background: #fff no-repeat center / cover;
		background-image: radial-gradient(#ffffff, #e4eef2);
	}

	@include m.mq-sp {
		font-size: 1.4rem;
	}
}

a {
	text-decoration: underline;
	color: var(--color-text-link);

	@include m.mq-hover {
		transition: opacity 0.2s linear;
		&:hover {
			opacity: 0.6;
		}
	}
}

img {
	width: 100%;
	height: auto;
	vertical-align: top;
}

table {
	width: 100%;
	text-align: left;
	border: none;
	font-size: 1.4rem;
	line-height: var(--line-height-m);

	@include m.mq-pc {
		font-size: 1.6rem;
	}
}

th, td {
	padding: 16px 8px;
	border: 1px solid var(--color-border);
	vertical-align: top;

	&:first-child {
		border-left: none;
	}

	&:last-child {
		border-right: none;
	}

	@include m.mq-pc {
		padding: 16px 24px;
	}
}

sup {
	font-size: 70%;
	vertical-align: top;
	position: relative;
	top: -0.1em;
}

input[type="text"],
textarea,
select {
	font-family: inherit;
	font-size: 1.6rem;
}

/*----------
	特定バグ対策・ポリフィル
----------*/
// iOSのbackground-attachementバグ対策
.is-iOS {
	*,
	*::before,
	*::after {
		background-attachment: initial !important;
	}
}
