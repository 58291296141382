@use 'sass:math';
@use '../_mixin' as m;
/*----------
	main navigation
----------*/
$_root: '.l-mainnav';

.l-mainnav {
	a {
		display: block;
		text-decoration: none;
		font-weight: 500;
		font-size: 1.6rem;
		line-height: 1;
		color: var(--color-text);
	}

	&__sub {
		i[class^="icon-"] {
			transform: translateY(0.1em);
		}
	}

	// SP
	@media (max-width: 1057px) {
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;

		&__inner {
			margin-top: 120px;
			padding: 0 20px 60px;
			height: calc(100% - 120px);
			position: relative;
			overflow-y: auto;
			overscroll-behavior: none;
		}

		&__gnav {
			> li {
				border-bottom: 1px solid var(--color-border);

				&:first-child {
					border-top: 1px solid var(--color-border);
				}

				> a {
					display: block;
					padding: 16px 8px 16px 40px;
					position: relative;

					&::before {
						@include m.iconfont-default;
						content: map-get(m.$icons, 'chev-sq-right');
						display: block;
						position: absolute;
						top: 17px;
						left: 8px;
						font-size: 16px;
					}
				}

				&:has(#{$_root}__sub) {
					> a {
						&::after {
							@include m.iconfont-default;
							content: map-get(m.$icons, 'subnav-toggle');
							display: block;
							position: absolute;
							top: 17px;
							right: 8px;
							transition: transform 0.2s ease-out;
						}
					}
				}

				&.sp-subnav-active {
					> a {
						&::after {
							transform: rotate(180deg);
						}
					}

					#{$_root}__sub {
						height: auto;
						opacity: 1;
						visibility: visible;
						pointer-events: all;
						padding: 24px;
					}
				}
			}
		}

		&__sub {
			height: 0;
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
			transition: opacity 0.2s linear, visibility 0.2s linear;

			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 0 16px;
			padding: 0;

			> li {
				> a {
					display: block;
					padding: 8px 0;
					font-size: 1.3rem;
					line-height: var(--line-height-s);
				}
			}
		}

		&__global,
		&__contact {
			margin-top: 24px;
			> a {
				font-weight: bold;
				font-size: 1.4rem;
			}
		}
	}




	// PC
	@media (min-width: 1058px) {
		&__inner {
			min-width: 1035px;
			padding: 34px 270px 0 40px;
		}

		&__gnav {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			> li {
				> a {
					position: relative;
					padding: 0 12px 40px 12px;
				}

				&.pc-subnav-active {
					#{$_root}__sub {
						opacity: 1;
						visibility: visible;
						pointer-events: all;
					}
				}

				&:has(#{$_root}__sub) {
					> a {
						&::after {
							@include m.iconfont-default;
							content: map-get(m.$icons, 'subnav-toggle');
							display: block;
							position: absolute;
							bottom: 18px;
							left: 50%;
							transform: translateX(-50%);
							font-size: 12px;
							color: #aaa;
						}
					}
				}
			}
		}

		&__sub {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			flex-wrap: wrap;
			gap: 32px;
			width: 1200px;
			min-height: 130px;
			padding: 40px 40px 40px 460px;
			border-radius: 6px;
			position: fixed;
			top: 96px;
			left: 50%;
			transform: translateX(-50%);
			background-color: #fff;
			box-shadow: 0px 2px 4px rgba(0,0,0,0.05);

			opacity: 0;
			visibility: hidden;
			pointer-events: none;
			transition: opacity 0.2s linear, visibility 0.2s linear;

			> li {
				flex: 0 0 30%;

				> a {
					display: block;
					position: relative;
					font-size: 1.8rem;
					line-height: var(--line-height-s);

					&:not(:has(i)) {
						padding: 0 0 0 24px;

						&::before {
							@include m.iconfont-default;
							content: map-get(m.$icons, 'chev-sq-right');
							position: absolute;
							top: 0.2em;
							left: 0;
							font-size: 18px;
						}
					}
				}

				&#{$_root}__cattop {
					width: 320px;
					position: absolute;
					top: 40px;
					left: 40px;

					> a {
						display: block;
						padding: 0 24px 14px 25px;
						border-bottom: 1px dashed #888;
						position: relative;
						font-size: 2.4rem;

						&::before {
							content: '';
							display: block;
							width: 5px;
							height: calc(100% - 20px);
							position: absolute;
							top: 4px;
							left: 0;
							background-color: #888;
						}
						&::after {
							@include m.iconfont-default;
							content: map-get(m.$icons, 'chev-right');
							display: block;
							position: absolute;
							top: 8px;
							right: 0;
							font-size: 14px;
						}
					}
				}

				&#subnavCoCh {
					flex-grow: 1;
					// grid-column: 3 / 6;
					// grid-row: 3 / 4;
				}
			}
		}

		&__global,
		&__contact {
			position: absolute;
			top: calc(50% - 20px);
			right: 30px;

			> a {
				display: block;
				width: 42px;
				height: 42px;
				border: 1px solid var(--color-border);
				border-radius: 50%;
				position: relative;
				background-color: #fff;

				&::after {
					@include m.iconfont-default;
					display: block;
					width: 18px;
					height: 18px;
					position: absolute;
					top: calc(50% - 9px);
					left: calc(50% - 9px);
					font-size: 18px;
				}

				> span {
					visibility: hidden;
				}
			}
		}
		&__global {
			> a::after {
				content: map-get(m.$icons, 'global');
			}
		}
		&__contact {
			right: 80px;
			> a::after {
				content: map-get(m.$icons, 'mail');
			}
		}
	}
}
