@use 'sass:math';
@use '../_mixin' as m;
/*----------
	local nav
----------*/
$_root: '.l-localnav';

.l-localnav {
	@include m.inner-content;
	margin-top: 100px;

	&__inner {
		border-radius: 6px;
		padding: 30px 20px;
		background-color: #fff;

		> dt {
			> a {
				display: block;
				position: relative;
				text-decoration: none;
				color: var(--color-text);

				&::after {
					@include m.iconfont-default;
					content: map-get(m.$icons, 'chev-right');
					display: block;
					width: 14px;
					height: 14px;
					position: absolute;
					top: 0.5em;
					right: 0;
					font-size: 14px;
				}
			}
		}
	}

	@include m.mq-sp {
		&__inner {
			> dd {
				margin-top: 40px;
			}
		}
	}

	@include m.mq-pc {
		margin-top: 150px;

		&__inner {
			padding: 40px;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			gap: 0 100px;

			> dt {
				flex: 0 0 326px;
			}

			> dd {
				flex: 1 1 auto;
			}
		}

		&__list {
			grid-template-columns: repeat(3, 1fr) !important;
		}
	}
}
