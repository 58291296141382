@use 'sass:math';
@use '../_mixin' as m;
/*----------
	vars
----------*/
:root,
::before, ::after {
	/* color */
	--color-theme-green: #{map-get(m.$colors, 'theme-green')};
	--color-theme-yellow: #{map-get(m.$colors, 'theme-yellow')};
	--color-theme-cyan: #{map-get(m.$colors, 'theme-cyan')};
	--color-theme-orange: #{map-get(m.$colors, 'theme-orange')};
	--color-theme-blue: #{map-get(m.$colors, 'theme-blue')};
	--color-theme-lime: #{map-get(m.$colors, 'theme-lime')};
	--color-theme-slateblue: #{map-get(m.$colors, 'theme-slateblue')};
	--color-border: #{map-get(m.$colors, 'border')};
	--color-bg: #{map-get(m.$colors, 'bg')};
	--color-caution: #{map-get(m.$colors, 'caution')};
	--color-text: #{map-get(m.$colors, 'text')};
	--color-text-caption: #{map-get(m.$colors, 'text-caption')};
	--color-text-link:#{map-get(m.$colors, 'text-link')};

	/* font family */
	--font-sans: 'Noto Sans JP', 'ヒラギノ角ゴシック', 'Hiragino Sans', '游ゴシック', 'Yu Gothic', '游ゴシック体', 'YuGothic', 'メイリオ', Meiryo, sans-serif;
	// --font-serif: 'Noto Serif JP', 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', '游明朝', 'Yu Mincho', '游明朝体', 'YuMincho', 'ＭＳ Ｐ明朝', 'MS PMincho', serif;
	--font-raj: 'Rajdhani', 'Noto Sans JP', sans-serif;

	/* line height */
	--line-height-s: 1.4;
	--line-height-m: 1.6;
	--line-height-l: 1.8;
	--line-height-xl: 2;

	/* icons */
	@each $name, $glyph in m.$icons {
		--icon-#{$name}: '#{$glyph}';
	}
}

