@use '_mixin' as m;
// ----------
// Utility
// ----------

/* margin */

/* 規定マージン（SP/PCでマージン幅が変わる） regular */
.u-mt-xs {
	margin-top: 20px !important;
}
.u-mt-s {
	margin-top: 40px !important;
}
.u-mt-m {
	margin-top: 60px !important;
}
.u-mt-l {
	margin-top: 80px !important;
}
.u-mt-xl {
	margin-top: 100px !important;
}

// margin-bottom
.u-mb-xs {
	margin-bottom: 20px !important;
}
.u-mb-s {
	margin-bottom: 40px !important;
}
.u-mb-m {
	margin-bottom: 60px !important;
}
.u-mb-l {
	margin-bottom: 80px !important;
}
.u-mb-xl {
	margin-bottom: 100px !important;
}

@include m.mq-pc {
	.u-mt-xs {
		margin-top: 30px !important;
	}
	.u-mt-s {
		margin-top: 50px !important;
	}
	.u-mt-m {
		margin-top: 80px !important;
	}
	.u-mt-l {
		margin-top: 120px !important;
	}
	.u-mt-xl {
		margin-top: 150px !important;
	}

	// margin-bottom
	.u-mb-xs {
		margin-bottom: 30px !important;
	}
	.u-mb-s {
		margin-bottom: 50px !important;
	}
	.u-mb-m {
		margin-bottom: 80px !important;
	}
	.u-mb-l {
		margin-bottom: 120px !important;
	}
	.u-mb-xl {
		margin-bottom: 150px !important;
	}
}

/* 固定マージン */
.u-mt-none {
	margin-top: 0 !important;
}
.u-mb-none {
	margin-bottom: 0 !important;
}
.u-mt-5 {
	margin-top: 5px !important;
}
.u-mb-5 {
	margin-bottom: 5px !important;
}

/* 10px倍数 */
@for $i from 1 through 10 {
	.u-mt-#{$i}0 {
		margin-top: #{$i}0px !important;
	}
}
@for $i from 1 through 10 {
	.u-mb-#{$i}0 {
		margin-bottom: #{$i}0px !important;
	}
}

/* display */
.u-sp-none {
	@include m.mq-sp {
		display: none !important;
	}
}

.u-pc-none {
	@include m.mq-pc {
		display: none !important;
	}
}

/* text */
br.u-pcbr {
	@include m.mq-sp {
		display: none !important;
	}
}

br.u-spbr {
	@include m.mq-pc {
		display: none !important;
	}
}

.u-text-center {
	text-align: center !important;
}

.u-text-pccenter {
	@include m.mq-pc {
		text-align: center !important;
	}
}

.u-text-right {
	text-align: right !important;
}

.u-text-left {
	text-align: left !important;
}

/* 文字装飾 */
.u-text-bold {
	font-weight: bold !important;
}

.u-text-strike {
	text-decoration: line-through !important;
}

.u-text-default {
	color: var(--color-text) !important;
}

.u-text-white {
	color: #fff !important;
}

.u-text-caution {
	color: var(--color-caution) !important;
}
